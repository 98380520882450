
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';
import * as API from "@/API/payment";
import { Modal } from 'ant-design-vue'
import { FileInfo } from '@/API/types';
import CommentModal from '@/views/Payment/components/commentModal.vue'
import { useStore } from 'vuex'
const attachmentColumns = [
  { title: "文件名称", dataIndex: "fileName", width: 200 },
  { title: "上传日期", dataIndex: "updatedAt", width: 110 },
  { title: "操作", key: "operation", width: 110, slots: { customRender: "action"}},
  { title: "被拒原因", dataIndex: "comment", width: 110, slots: { customRender: "comment"} }
]
type CommentData = {
  comment: string;
  date: string;
}
type AttachmentData = {
  fileName: string;
  updatedAt: string;
  commentList: CommentData[];
  id: string;
}

export default defineComponent({
  components: {
    CommentModal
  },
  emits: ['close-upload-visible', 'change-table'],
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    dealerDetailApproveId: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props, context) {
    const { state } = useStore()

    const check = ref('upload')
    const comment = ref('')

    const myAttachmentData = ref<null | AttachmentData[]>(null)
    const getFileList = () => {
      API.breakDownDetailFileList(props.dealerDetailApproveId).then((res) => {
        myAttachmentData.value = res
      })
    }
    getFileList()
    onMounted(() => {
     //rrrr
    })
    // 文件上传
    const uploading = ref<boolean>(false);
    const isLt5M = ref<boolean[]>([]);
    const uploadComplete = ref<boolean>(true);
    // 获取标题内容
    const title = computed(() => `上传文件`)
    // // 设置抽屉显示状态
    // const modal = (props as any).isUploadVisible;
    const close = () => {
      context.emit('close-upload-visible', { isUploadVisible: false })
    }

    const fileList = ref<FileItem[]>([]);
    // 上传前
    const beforeUpload = (file: FileItem) => {
      console.log('进入上传前方法中。。。。。。。')
      // const isHtml = file.type === 'text/html';
      // if (isHtml) {
      //   message.error('不能上传html格式文件!')
      // }
      isLt5M.value.push(file.size / 1024 / 1024 < state.payment.maxFileSize)
      /*if (file.size / 1024 / 1024 > 5) {
        message.error('图片不得大于5MB!')
      }*/
      return false
    }

    const fileChange = (info: FileInfo) => {
      const lastFile: FileItem = info.fileList[info.fileList.length - 1]
      if (lastFile && lastFile.type === 'text/html') {
        message.error('Attachment can not be an HTML file.')
        fileList.value.splice(info.fileList.length - 1, 1)
        isLt5M.value.splice(info.fileList.length - 1, 1)
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
      isLt5M.value = []
      //console.log('isLt5m===============',isLt5M)
      fileList.value.forEach((item: FileItem)=>{
        isLt5M.value.push(item.size / 1024 / 1024 < state.payment.maxFileSize)
      })
    };
    const submitFile = () => {
      console.log('上传的附件个数为：',fileList.value.length)
      const formData = new FormData();
      if (fileList.value.length<=0){
        message.warning('you have not uploaded any attachments');
        return
      }
      if (fileList.value.length>1){
        message.warning('Attachment batch upload can not more than 1.');
        return
      }
      const isMore5m = isLt5M.value.every(( item )=>{
          return item;
      })
      if (!isMore5m){
        message.warning(`Attachment can not large than ${state.payment.maxFileSize}M.`);
        return
      }
      fileList.value.forEach((file: FileItem) => {
        formData.append('document', file.originFileObj);
      });
      uploading.value = true;
      const params = {
        dealerDetailApproveId: props.dealerDetailApproveId
      }
      API.breakDownDetailApprove(params, formData).then((data: any) => {
        fileList.value = [];
        uploading.value = false;
        if (data.code !== '0') {
          message.warning(data.message)
        } else {
          message.success('文件上传成功')
          getFileList()
          context.emit('change-table')
        }
      })
    }

    const rejectFunc = () => {
      const params = {
        dealerDetailApproveId: props.dealerDetailApproveId,
        comment: comment.value
      }
      if (fileList.value.length > 0) {
        message.warning('拒绝无需上传文件，请删除已上传文件后重新提交')
        return
      }
      API.breakDownDetailReject(params).then((res: any) => {
        if (res.code === '0') {
          message.warning('拒绝成功')
          getFileList()
          context.emit('change-table')
        } else {
          message.warning(res.message)
        }
      })
    }

    const uploadClick = () => {
      if (check.value === 'upload') {
        if (fileList.value.length === 0) {
          message.warning('请上传盖章版折让明细')
          return
        }
        submitFile()
      } else {
        if (!comment.value) {
          message.warning('请输入拒绝理由再提交')
          return
        }
        rejectFunc()
      }
    }

    const downloadAttachment = (record: any)=>{
      API.getSalesDownloadFile(record.fileId).then(data => {
        const downloadElement = document.createElement('a')
        downloadElement.href = data
        downloadElement.click()
      })
    }
    
    const commentVisible = ref(false)
    const commentData = ref<CommentData[]>()
    const openComment = (record: AttachmentData) => {
      if (record.commentList.length === 0) return
      commentData.value = record.commentList
      commentVisible.value = true
    }
    return {
      myAttachmentData,
      fileList,
      title,
      // modal,
      close,
      isLt5M,
      submitFile,
      beforeUpload,
      handleRemove,
      uploadComplete,
      attachmentColumns,
      downloadAttachment,
      fileChange,
      uploading,
      check,
      comment,
      uploadClick,
      commentVisible,
      commentData,
      openComment
    }
  }
})
